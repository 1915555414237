html.overlay-open .navbar-fixed-top {
  z-index: 400;
}
html.js fieldset.collapsed {
  height: auto;
}
html.js input.form-autocomplete {
  background-position: 100% 8px;
  /* LTR */
  background-repeat: no-repeat;
}
html.js input.throbbing {
  background-position: 100% -13px;
  /* LTR */
}
body,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  line-height: 1.25em;
}
@media (max-width: 767px) {
  body {
    position: relative;
  }
}
@media (min-width: 980px) and (max-width: 1199px), (min-width: 1200px) {
}
body.toolbar .navbar-fixed-top {
  top: 30px;
}
body.toolbar-drawer .navbar-fixed-top {
  top: 65px;
}
body.admin-expanded.admin-vertical.admin-nw .navbar,
body.admin-expanded.admin-vertical.admin-sw .navbar {
  margin-left: 260px;
}
body div#admin-toolbar {
  z-index: 500;
}
@media (min-width: 980px) and (max-width: 1199px), (min-width: 1200px) {
  body.toolbar {
    padding-top: 94px !important;
  }
  body.toolbar-drawer {
    padding-top: 129px !important;
  }
}
body #admin-menu {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  margin: 0;
  padding: 0;
  position: fixed;
  z-index: 1100;
}
body #admin-menu .dropdown li {
  line-height: normal;
}
html body.admin-menu {
  margin-top: 28px !important;
}
body.admin-menu .navbar-fixed-top {
  top: 28px;
}
.footer {
  margin-top: 45px;
  padding: 35px 0 36px;
  border-top: 1px solid #E5E5E5;
}
.element-invisible {
  margin: 0;
  padding: 0;
  width: 1px;
}
.navbar .logo {
  margin-top: 5px;
  margin-right: 10px;
}
#site-name {
  line-height: 1;
  margin: 0;
}
.page-header {
  margin-top: 0;
}
.block h2.block-title {
  margin-top: 0;
}
form#search-block-form {
  margin: 0;
}
.navbar #block-search-form {
  float: right;
  margin: 5px 0 5px 5px;
}
@media (max-width: 767px), (min-width: 768px) and (max-width: 979px) {
  .navbar #block-search-form {
    float: none;
  }
}
fieldset.search-advanced {
  padding-bottom: 1.5em;
  margin-top: 1em;
}
.navbar-search .control-group {
  margin-bottom: 0px;
}
ul.action-links {
  margin-bottom: 2em;
}
ul.action-links li {
  display: inline;
  padding-right: 1.5em;
}
ul.action-links [class^="icon-"],
ul.action-links [class*=" icon-"] {
  padding-right: 0.5em;
}
input,
textarea,
select,
.uneditable-input {
  max-width: 100%;
  width: auto;
}
select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: auto;
}
input.error {
  color: #b94a48;
  border-color: #eed3d7;
}
.form-type-checkbox input,
.form-type-radio input {
  float: left;
}
.form-actions {
  clear: both;
}
.resizable-textarea textarea {
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}
.help-block,
.control-group .help-inline {
  color: #999;
  font-size: 12px;
  margin: -8px 0 10px;
  padding: 0 3px;
}
.description + .help-block,
.description + .control-group .help-inline,
.checkbox + .help-block,
.checkbox + .control-group .help-inline,
.form-type-checkbox + .help-block,
.form-type-checkbox + .control-group .help-inline,
.form-type-radio + .help-block,
.form-type-radio + .control-group .help-inline,
.form-file + .help-block,
.form-file + .control-group .help-inline,
.resizable-textarea + .help-block,
.resizable-textarea + .control-group .help-inline {
  margin-top: 0;
}
.input-append {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-right: 40px;
  width: 100%;
}
.input-append input,
.input-append textarea,
.input-append select,
.input-append .uneditable-input {
  width: 100% !important;
}
.container-inline div,
.container-inline label {
  display: block;
}
div.error,
table tr.error {
  background-color: #f2dede;
  color: #b94a48;
}
.control-group.error {
  background: none;
}
.control-group.error label,
.control-group.error .control-label {
  color: #b94a48;
  font-weight: 600;
}
.control-group.error input,
.control-group.error textarea,
.control-group.error select,
.control-group.error .uneditable-input {
  color: #b94a48;
  border: 1px solid #ccc;
}
.control-group.error .help-block,
.control-group.error .help-inline {
  color: #999;
}
ul li.collapsed,
ul li.expanded,
ul li.leaf {
  list-style: none;
  list-style-image: none;
}
.vertical-tabs .form-type-textfield input {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
  width: auto;
}
.vertical-tabs .form-item {
  margin: 1em 0;
}
.submitted {
  margin-bottom: 1em;
  font-style: italic;
  font-weight: normal;
  color: #777;
}
.password-strength {
  width: 17em;
  float: right;
  /* LTR */
  margin-top: 1.4em;
}
.password-strength-title {
  display: inline;
}
.password-strength-text {
  float: right;
  /* LTR */
  font-weight: bold;
}
.password-indicator {
  background-color: #e9e9e9;
  height: 0.3em;
  width: 100%;
}
.password-indicator div {
  height: 100%;
  width: 0%;
  background-color: #46a546;
}
input.password-confirm,
input.password-field {
  width: 16em;
  margin-bottom: 0.4em;
}
div.password-confirm {
  float: right;
  /* LTR */
  margin-top: 1.5em;
  visibility: hidden;
  width: 17em;
}
div.form-item div.password-suggestions {
  padding: 0.2em 0.5em;
  margin: 0.7em 0;
  width: 38.5em;
  border: 1px solid #B4B4B4;
}
div.password-suggestions ul {
  margin-bottom: 0;
}
.confirm-parent,
.password-parent {
  clear: left;
  /* LTR */
  margin: 0;
  width: 36.3em;
}
.progress-wrapper .progress {
  margin-bottom: 10px;
}
.progress-wrapper .progress .bar {
  border: 0 none;
  margin: 0;
}
.pagination ul > li > a.progress-disabled {
  float: left;
}
.pagination ul > li > a .throbber {
  margin: 0 -0.25em 0 0.5em;
  position: relative;
  top: 1px;
}
body {
  font-size: 16px;
}
h1 {
  font-size: 35px;
}
#admin-menu {
  font-size: 1em;
}
#block-views-front-banner-block {
  margin-bottom: 20px;
}
.region-footer {
  position: relative;
}
/*-----表單相關----*/
form .description {
  color: #888;
}
#autocomplete li.selected {
  background-color: #1A4666;
  color: white;
}
/*聯絡表單*/
#webform-client-form-29 .webform-component > label {
  width: 80px;
  display: inline-block;
  font-weight: bold;
  margin-top: 15px;
}
#webform-client-form-29 .form-text {
  width: 200px;
}
#edit-submitted-contact-req div {
  display: inline-block;
}
#webform-client-form-29 .captcha legend {
  display: none;
}
#block-menu-menu-about {
  position: absolute;
  top: 0px;
  right: 0px;
}
#block-menu-menu-about li {
  display: inline-block;
  margin-right: 10px;
}
.node {
  margin-bottom: 20px;
}
/*# sourceMappingURL=style.css.map */